import React from 'react'
import { css } from 'linaria'
import { colors } from '../../../utils/theme'
import rhythm from '../../../utils/rhythm'
import breakpoints from '../../../utils/tokens/breakpoints'

const columns = css`
  display: grid;
  background: ${colors.background.lightGray};
  margin: 0 -8vw;
  padding: ${rhythm(1)} 8vw 0;
  border-radius: 16px;
  column-gap: ${rhythm(2)};
  margin-bottom: ${rhythm(2)};

  @media (min-width: ${breakpoints.md}) {
    grid-template-columns: 1fr 1fr;
    margin: ${rhythm(2)} -${rhythm(4)};
    padding: ${rhythm(2)} ${rhythm(4)} ${rhythm(1)};
  }
`

const Columns = ({ children }) => <div className={columns}>{children}</div>

export default Columns
