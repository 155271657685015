import React, { useEffect, useState } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { css } from 'linaria'
import Img from 'gatsby-image'

import { colors } from '../../utils/theme'
import rhythm from '../../utils/rhythm'
import hexToRgb from '../../utils/hexToRgb'
import Button from '../../components/Button'
import breakpoints from '../../utils/tokens/breakpoints'
import cx from '../../utils/cx'
import { ga } from '../../utils/analytics'

const container = css`
  background: ${colors.accent.purple};
  padding: ${rhythm(3 / 2)} 8vw;
  color: ${colors.text.white};
  position: relative;
  overflow: hidden;
  margin-left: -8vw;
  margin-right: -8vw;

  @media (min-width: ${breakpoints.md}) {
    margin: 0 0 ${rhythm(2)};
    border-radius: 28px;
  }

  @media (min-width: ${breakpoints.lg}) {
    margin: 0 4vw ${rhythm(2)};
    padding-left: ${rhythm(2)};
    padding-right: ${rhythm(2)};
  }

  @media (min-width: ${breakpoints.xl}) {
    margin-left: ${rhythm(5)};
    margin-right: ${rhythm(5)};
  }
`

const content = css`
  position: relative;
  z-index: 1;
  color: #fff;

  p {
    font-size: 1rem;
  }

  @media (min-width: ${breakpoints.lg}) {
    p {
      font-size: 1.125rem;
    }
  }
`

const formClass = css`
  margin-top: ${rhythm(1)};
  margin-bottom: 0;
  width: 100%;
  ${'' /* display: flex; */}
  flex-wrap: wrap;
  font-size: initial;

  button {
    width: 100%;
    margin-top: ${rhythm(1 / 2)};

    @media (min-width: ${breakpoints.md}) {
      width: auto;
    }
  }
`

const inputClass = css`
  border-radius: 28px;
  padding: ${rhythm(2 / 3)} ${rhythm(1)};
  border: 0;
  box-shadow: 0 9px 60px 0 rgba(${hexToRgb(colors.accent.purple)}, 0.35);
  width: 100%;

  @media (min-width: ${breakpoints.md}) {
    margin-right: ${rhythm(1 / 2)};
    max-width: ${rhythm(14)};
  }
`

const mobileBackground = css`
  border-radius: 0;
  position: absolute !important;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 0;

  @media (min-width: ${breakpoints.sm}) {
    display: none;
  }
`

const desktopBackground = css`
  border-radius: 28px;
  display: none;
  position: absolute !important;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 0;

  @media (min-width: ${breakpoints.sm}) {
    display: block;
  }
  @media (min-width: ${breakpoints.lg}) {
    border-radius: 28px;
  }
`

const errorMessage = css`
  margin-top: ${rhythm(1 / 2)};

  a {
    color: #fff;
  }
`

const MailchimpForm = ({ ctaText }) => {
  const [status, setStatus] = useState('')
  const [buttonText, setButtonText] = useState(ctaText)
  const [emailVal, setEmail] = useState('')

  useEffect(() => {
    if (status === 'success') {
      ga('send', {
        hitType: 'event',
        eventCategory: 'Newsletter',
        eventAction: 'signing',
        eventLabel: 'Signed up successfully'
      })
    }

    if (status === 'error') {
      ga('send', {
        hitType: 'event',
        eventCategory: 'Newsletter',
        eventAction: 'signing',
        eventLabel: 'Signing error'
      })
    }
  }, [status])

  const clearForm = (delay = 2000) => {
    setTimeout(() => {
      setEmail('')
      setStatus(null)
      setButtonText(ctaText)
    }, delay)
  }

  useEffect(() => {
    if (status === 'sending') {
      setButtonText('Sending...')
    }

    if (status === 'error') {
      setButtonText(ctaText)
    }

    if (status === 'exists') {
      setButtonText('Already subscribed.')
      clearForm()
    }

    if (status === 'success') {
      setButtonText('Done!')
      clearForm()
    }
  }, [status])

  const sendRequest = async e => {
    e.preventDefault()
    setStatus('sending')

    try {
      const response = await fetch('https://api.bejamas.io/v1/add-subscriber', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          email: emailVal,
          redirect: false
        })
      })

      const { status, title } = await response.json()

      if (status === 'subscribed') {
        setStatus('success')
      } else if (status === 400 && title === 'Member Exists') {
        setStatus('exists')
      } else {
        setStatus('error')
      }
    } catch (e) {
      setStatus('error')
      console.log(e)
    }
  }

  return (
    <form
      className={formClass}
      action="https://api.bejamas.io/v1/add-subscriber"
      method="POST"
      onSubmit={sendRequest}
    >
      <input
        className={inputClass}
        type="email"
        name="email"
        id="email"
        placeholder="Your email"
        value={emailVal}
        onChange={e => setEmail(e.target.value)}
        required
      />
      <Button tag="button" type="submit" disabled={status === 'sending'}>
        {buttonText}
      </Button>
      {status === 'error' && (
        <div
          className={errorMessage}
          dangerouslySetInnerHTML={{ __html: 'error' }}
        />
      )}
    </form>
  )
}

const Newsletter = ({
  title = 'Join our newsletter',
  description = 'Sign up and be the first to know about new blog posts.',
  ctaText = 'Submit',
  className
}) => {
  const data = useStaticQuery(graphql`
    query NewsletterQuery {
      image: file(
        relativePath: { regex: "/img/blog/" }
        name: { eq: "newsletter-bg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1200, traceSVG: { color: "#0a0116" }) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      imageMobile: file(
        relativePath: { regex: "/img/blog/" }
        name: { eq: "newsletter-mobile-bg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 800, traceSVG: { color: "#0a0116" }) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
    }
  `)

  return (
    <section
      className={cx({
        [container]: true,
        [className]: Boolean(className)
      })}
    >
      <Img
        fluid={data.imageMobile.childImageSharp.fluid}
        className={mobileBackground}
      />
      <Img
        fluid={data.image.childImageSharp.fluid}
        className={desktopBackground}
      />
      <div className={content}>
        <h2>{title}</h2>
        <p>{description}</p>
        <MailchimpForm ctaText={ctaText} />
      </div>
    </section>
  )
}

export default Newsletter
