import React, { useRef, useEffect, useState } from 'react'
import { css } from 'linaria'
import useScript from '../../utils/hooks/useScript'
import rhythm from '../../utils/rhythm'
import breakpoints from '../../utils/tokens/breakpoints'

function isHubspotUrl(url) {
  var hubspotUrls = [
    'https://local.hubspot.com',
    'https://app.hubspotqa.com',
    'https://app.hubspot.com',
    'https://meetings.hubspot.com'
  ]
  return hubspotUrls.indexOf(url) > -1
}

const MeetingForm = () => {
  const calendarPlaceholder = useRef(null)
  const [calendarHeight, setHeight] = useState(0)

  const [load, error] = useScript(
    'https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js'
  )

  const setCalendarStyles = calendar => {
    if (calendarPlaceholder && calendarPlaceholder.current) {
      let offsetTop = calendarPlaceholder.current.offsetTop

      calendar.style.visibility = 'visible'
      calendar.style.opacity = '1'
      calendar.style.top = `${offsetTop}px`
      calendar.style.left = '0'
    }
  }

  const setPlaceholderHeight = calendar => {
    if (calendarHeight !== calendarHeight.clientHeight) {
      setHeight(calendar.clientHeight)
    }
  }

  useEffect(() => {
    let calendar = document.getElementsByClassName(
      'meetings-iframe-container'
    )[0]

    if (!calendar) {
      return
    }

    setPlaceholderHeight(calendar)
    setCalendarStyles(calendar)

    return () => {
      calendar.style.visibility = 'hidden'
      calendar.style.opacity = '0'
      calendar.style.top = '0px'
      calendar.style.left = '-9999em'
    }
  }, [load, calendarPlaceholder])

  useEffect(() => {
    let calendar = document.getElementsByClassName(
      'meetings-iframe-container'
    )[0]

    if (!calendar) {
      return
    }

    window.addEventListener(
      'resize',
      () => {
        setPlaceholderHeight(calendar)
        setCalendarStyles(calendar)
      },
      true
    )
    return () =>
      window.removeEventListener('resize', () => {
        setPlaceholderHeight(calendar)
        setCalendarStyles(calendar)
      })
  }, [calendarPlaceholder])

  const receiveMessage = event => {
    if (isHubspotUrl(event.origin) && event.data.meetingCreated) {
      ga('send', {
        hitType: 'event',
        eventCategory: 'Meetings',
        eventAction: 'booking',
        eventLabel: 'Meeting Booked'
      })
      console.log('hubspot meetings: meetingCreated event')
    }

    if (isHubspotUrl(event.origin) && event.data.meetingBookSucceeded) {
      ga('send', {
        hitType: 'event',
        eventCategory: 'Meetings',
        eventAction: 'booking',
        eventLabel: 'Meeting Booked'
      })
      console.log('hubspot meetings: meetingBookSucceeded event')
    }
  }

  useEffect(() => {
    window.addEventListener('message', receiveMessage, true)
    return () => window.removeEventListener('message', receiveMessage)
  }, [calendarPlaceholder])

  return (
    <div
      ref={calendarPlaceholder}
      className={css`
        ${{ marginTop: rhythm(1) }}
      `}
      style={{ height: calendarHeight }}
    />
  )
}

export default MeetingForm

export const globals = css`
  :global() {
    .meetings-iframe-container {
      position: absolute;
      width: 100%;
      opacity: 0;
      visibility: hidden;
      left: -9999em;
      padding-left: 4vw;
      padding-right: 4vw;

      @media (min-width: ${breakpoints.sm}) {
        padding-left: ${rhythm(3 / 2)};
        padding-right: ${rhythm(3 / 2)};
      }
    }
  }
`
