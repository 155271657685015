import React from 'react'
import { css } from 'linaria'
import rhythm from '../../utils/rhythm'
import { colors } from '../../utils/theme'

const metaBoxContainer = css`
  ${'' /* max-width: ${rhythm(29)}; */}
  max-width: 42.5rem;
  margin: 0 auto;
  border-top: 2px solid ${colors.background.lightGray};
  padding: ${rhythm(1)} 0;
  display: flex;
  justify-content: flex-start;

  .socials {
    margin-right: auto;
    margin-left: ${rhythm(1)};
  }
`

const metaBoxTitle = css`
  font-size: 1.25rem;
`

const MetaBox = ({ children, title }) => (
  <div className={metaBoxContainer}>
    <span className={metaBoxTitle}>{title}</span>
    {children}
  </div>
)

export default MetaBox
