import React from 'react'
import Img from 'gatsby-image'
import { css } from 'linaria'
import delve from 'dlv'
import { colors } from '../../utils/theme'
import rhythm from '../../utils/rhythm'

const heading = css`
  font-size: 1.25rem;
  width: 100%;
  margin-bottom: ${rhythm(3 / 2)};
`

const authorContainer = css`
  background: ${colors.background.lightGray};
  border-radius: 1rem;
  padding: ${rhythm(3 / 2)} ${rhythm(2)} ${rhythm(3 / 2)};
  ${'' /* max-width: ${rhythm(29)}; */}
  max-width: 42.5rem;
  margin: ${rhythm(1)} auto ${rhythm(2)};
  display: flex;
  flex-wrap: wrap;
`

const avatar = css`
  margin-right: ${rhythm(3 / 2)};
`

const content = css`
  flex: 1;
  color: ${colors.text.lightGray};
`

const name = css`
  color: ${colors.text.darkGray};
`

const Author = props => (
  <div className={authorContainer}>
    <p className={heading}>About author</p>
    {delve(props, 'photo.childImageSharp.fixed') && (
      <Img
        fixed={props.photo.childImageSharp.fixed}
        alt={props.title}
        className={avatar}
      />
    )}
    <div className={content}>
      <p className={name}>{props.title}</p>
      <p>{props.description}</p>
    </div>
  </div>
)

export default Author
