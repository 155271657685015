import React from 'react'
import { css } from 'linaria'
import NewsletterBox from '../Newsletter'
import rhythm from '../../../utils/rhythm'
import breakpoints from '../../../utils/tokens/breakpoints'

const Newsletter = ({ title, description, ctaText }) => (
  <NewsletterBox
    className={css`
      ${{
        marginTop: `${rhythm(3 / 2)}`,
        marginBottom: `${rhythm(3 / 2)}`,
        [`@media (min-width: ${breakpoints.md})`]: {
          margin: `${rhythm(2)} -${rhythm(3)}`,
          padding: `${rhythm(2)} ${rhythm(3)}`
        }
      }}
    `}
    title={title}
    description={description}
    ctaText={ctaText}
  />
)

export default Newsletter
