import React from 'react'
import { css } from 'linaria'
import rhythm from '../../../utils/rhythm'
import { colors } from '../../../utils/theme'
import breakpoints from '../../../utils/tokens/breakpoints'

const list = css`
  font-size: 1rem;

  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      margin-bottom: ${rhythm(1)};

      p {
        color: ${colors.text.lightGray};

        &:first-child {
          margin-bottom: ${rhythm(1 / 2)};
          color: ${colors.text.darkGray};
          font-size: 1.25rem;
        }
      }

      @media (min-width: ${breakpoints.md}) {
        margin-bottom: ${rhythm(2)};
      }
    }
  }
`

const List = ({ children }) => <div className={list}>{children}</div>

export default List
