import React from 'react'
import { css } from 'linaria'
import rhythm from '../../../utils/rhythm'
import { colors } from '../../../utils/theme'
import cx from '../../../utils/cx'
import breakpoints from '../../../utils/tokens/breakpoints'

const avatar = css`
  width: 40px;
  height: 40px;
  position: absolute;
  top: 0;
  margin: 0 0 -50px calc(-40px - ${rhythm(1)});
  display: none;

  @media (min-width: ${breakpoints.lg}) {
    display: block;
  }
`

const message = css`
  position: relative;
  font-size: 1.125rem;
  margin: ${rhythm(1)} 0;
`

const lastStyle = css`
  margin-bottom: ${rhythm(3 / 2)};
`

const content = css`
  background: ${colors.background.lightGray};
  border-radius: 0 16px 16px;
  padding: ${rhythm(1)};
  position: relative;

  p:last-child {
    margin-bottom: 0;
  }

  .gatsby-highlight {
    margin: 0 !important;

    > pre {
      padding: 0 !important;
    }
  }
`

const note = css`
  border-left: 4px solid ${colors.accent.purple};
  padding-top: ${rhythm(5 / 3)};

  &::before {
    content: 'QUICK NOTE';
    background: ${colors.accent.purple};
    color: ${colors.text.white};
    position: absolute;
    left: 0;
    top: 0;
    font-size: 0.75rem;
    padding: 2px 6px 2px 4px;
    border-radius: 0 0 4px 0;
  }
`

const Message = ({ author, children, type, last = false }) => {
  let photo = require(`../../../data/team/images/${author}.png`)

  const contentClasses = cx({
    [content]: true,
    [note]: type === 'note'
  })

  const messageClasses = cx({
    [message]: true,
    msg: true,
    [lastStyle]: Boolean(last)
  })

  return (
    <div className={messageClasses}>
      <img src={photo} className={avatar} />
      <div className={contentClasses}>{children}</div>
    </div>
  )
}

export default Message
