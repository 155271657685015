import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import { css } from 'linaria'
import { colors } from '../../utils/theme'

import { ReactComponent as Facebook } from 'assets/svg/icons/facebook.svg'
import { ReactComponent as Twitter } from 'assets/svg/icons/twitter.svg'
import { ReactComponent as LinkedIn } from 'assets/svg/icons/linkedin.svg'
import rhythm from '../../utils/rhythm'

const list = css`
  list-style: none;
  display: flex;
  margin: 0 0 0 auto;
  line-height: 2rem;

  li {
    margin: 0;

    + li {
      margin-left: ${rhythm(1)};
    }
  }

  a {
    color: ${colors.text.lightGray};
  }
`

function useSiteMetadata() {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            siteUrl
          }
        }
      }
    `
  )
  return site.siteMetadata
}

const SocialSharers = ({ pathname = '', title }) => {
  const { siteUrl } = useSiteMetadata()
  let url = encodeURIComponent(siteUrl + pathname)
  let encodedTitle = encodeURIComponent(title)

  return (
    <ul className={`${list} socials`}>
      <li>
        <a
          href={`https://www.facebook.com/sharer/sharer.php?u=${url}&quote=${encodedTitle}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Facebook />
        </a>
      </li>
      <li>
        <a
          href={`https://twitter.com/share?url=${url}&text=${encodedTitle}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Twitter />
        </a>
      </li>
      <li>
        <a
          href={`https://linkedin.com/shareArticle?url=${url}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <LinkedIn />
        </a>
      </li>
    </ul>
  )
}
export default SocialSharers
