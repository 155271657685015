import React from 'react'
import { Link } from 'gatsby'
import OutboundLink from '../../OutboundLink'

const BlogLink = ({ children, rel, href, ...rest }) => {
  let isInternal = href.includes('bejamas.io')

  if (isInternal) {
    let to = href.replace(/^.*\/\/[^\/]+/, '')

    return (
      <Link to={to} {...rest}>
        {children}
      </Link>
    )
  }

  return (
    <OutboundLink href={href} rel={rel} {...rest}>
      {children}
    </OutboundLink>
  )
}

export default BlogLink
