import React, { useRef } from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/Layout'
import Progress from '../components/Blog/Progress'
import SinglePost from '../components/Blog/SinglePost'
import RelatedPosts from '../components/Blog/RelatedPosts'
import JsonLD, { printFAQ } from '../components/Blog/JsonLD'
import { Meta } from 'react-head'
import delve from 'dlv'

const BlogPost = ({
  location,
  data: { post, category, author, relatedPosts }
}) => {
  const postEl = useRef(null)

  const faqSchema =
    delve(post, 'frontmatter.json_ld_data') &&
    post.frontmatter.json_ld_data[0] &&
    `
    {
      "@context": "https://schema.org",
      "@type": "FAQPage",
      "mainEntity": [${printFAQ(post.frontmatter.json_ld_data[0].faq)}]
    }
  `

  return (
    <Layout
      location={location}
      title={post.frontmatter.metaTitle || post.frontmatter.title}
      addSuffixToTitle
      description={
        post.frontmatter.metaDescription || post.frontmatter.excerpt || null
      }
      image={post.frontmatter.ogImage}
      showCtaInHeader={true}
      preHeaderComponent={
        <Progress
          postTitle={post.frontmatter.title}
          postRef={postEl}
          pathname={location.pathname}
        />
      }
      jsonLD={faqSchema}
    >
      <SinglePost
        post={post}
        author={author}
        ref={postEl}
        pathname={location.pathname}
        locationState={location.state}
      />
      <RelatedPosts {...relatedPosts} />
      <Meta
        name="twitter:label1"
        content="Read time"
        data-pn={location.pathname}
      />
      <Meta
        name="twitter:data1"
        content={`${post.timeToRead} min ⏱️`}
        data-pn={location.pathname}
      />
      {category && category.title && (
        <>
          <Meta
            name="twitter:label2"
            content="Category"
            data-pn={location.pathname}
          />
          <Meta
            name="twitter:data2"
            content={category.title}
            data-pn={location.pathname}
          />
        </>
      )}
    </Layout>
  )
}
export default BlogPost

export const pageQuery = graphql`
  query blogPostQuery(
    $slug: String!
    $author: String!
    $category: String!
    $relatedPosts: [String]!
  ) {
    post: markdownRemark(fields: { slug: { eq: $slug } }) {
      frontmatter {
        metaTitle
        metaDescription
        title
        date(formatString: "MMMM D, YYYY")
        excerpt
        ogImage
        image {
          childImageSharp {
            fluid(maxWidth: 445) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
        json_ld_data {
          faq {
            question
            answer
          }
        }
      }
      timeToRead
      htmlAst
    }
    category: blogCategoriesYaml(slug: { eq: $category }) {
      title
    }
    author: blogAuthorsYaml(slug: { eq: $author }) {
      title
      role
      description
      photo {
        childImageSharp {
          fixed(width: 80) {
            ...GatsbyImageSharpFixed_withWebp_tracedSVG
          }
        }
      }
    }
    relatedPosts: allMarkdownRemark(
      filter: { fileAbsolutePath: { in: $relatedPosts } }
    ) {
      edges {
        node {
          frontmatter {
            title
            excerpt
            image {
              childImageSharp {
                fluid(maxWidth: 350) {
                  ...GatsbyImageSharpFluid_withWebp_tracedSVG
                }
                fixed(width: 350, height: 350) {
                  ...GatsbyImageSharpFixed_withWebp_tracedSVG
                }
              }
            }
          }
          fields {
            slug
          }
        }
      }
    }
  }
`
