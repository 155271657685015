import React from 'react'
import { css } from 'linaria'
import rhythm from '../../utils/rhythm'
import PostTile from './PostTile'
import breakpoints from '../../utils/tokens/breakpoints'

const container = css`
  margin: ${rhythm(3)} 0;
`

const header = css`
  margin-bottom: ${rhythm(3)};
  text-align: center;
`

const postsContainer = css`
  display: grid;
  justify-content: center;
  column-gap: ${rhythm(2)};
  row-gap: ${rhythm(2)};

  @media (min-width: ${breakpoints.md}) {
    grid-template-columns: 1fr 1fr 1fr;
    padding-left: ${rhythm(5)};
    padding-right: ${rhythm(5)};
  }
`

const RelatedPosts = posts => {
  if (posts.edges.length <= 0) {
    return null
  }

  return (
    <section className={container}>
      <h2 className={header}>Readers also enjoyed</h2>
      <div className={postsContainer}>
        {posts && posts.edges.map(({ node }) => <PostTile post={node} />)}
      </div>
    </section>
  )
}

export default RelatedPosts
