import React from 'react'
import rehypeReact from 'rehype-react'

import Columns from './snippets/Columns'
import List from './snippets/List'
import HubspotCalendar from './snippets/HubspotCalendar'
import Newsletter from './snippets/Newsletter'
import Message from './snippets/Message'
import BlogLink from './snippets/BlogLink'
import Lighthouse from './snippets/Lighthouse'

const renderAst = new rehypeReact({
  createElement: React.createElement,
  Fragment: React.Fragment,
  components: {
    columns: Columns,
    list: List,
    'booking-calendar': HubspotCalendar,
    newsletter: Newsletter,
    message: Message,
    lighthouse: Lighthouse,
    a: BlogLink
  }
}).Compiler

export default renderAst
