import React from 'react'
import { css } from 'linaria'
import Img from 'gatsby-image'
import { Link } from 'gatsby'
import delve from 'dlv'

import MetaBox from './MetaBox'
import SocialSharers from './SocialSharers'
import Author from './Author'
import { Lead, HeroHeading } from '../TextElements'

import rhythm from '../../utils/rhythm'
import cx from '../../utils/cx'

import { colors, transition } from '../../utils/theme'
import breakpoints from '../../utils/tokens/breakpoints'
import renderAst from './renderAst'
import prismStyles from './prismStyles'
import hexToRgb from '../../utils/hexToRgb'

const container = css`
  padding: 0;
  max-width: 72rem;
  margin-left: auto;
  margin-right: auto;

  ${prismStyles};

  .gatsby-highlight {
    margin: ${rhythm(1)} -8vw;

    > pre {
      font-size: 0.875rem;
      padding: ${rhythm(1)} 8vw;

      @media (min-width: ${breakpoints.sm}) {
        padding: ${rhythm(3 / 2)} 8vw;
      }
    }

    @media (min-width: ${breakpoints.md}) {
      margin: ${rhythm(2)} ${rhythm(-3)};

      > pre {
        font-size: 1rem;
        border-radius: 28px;
        padding: ${rhythm(3 / 2)} ${rhythm(3)};
      }
    }
  }
`

const postContent = css`
  ${'' /* max-width: ${rhythm(28)}; */}
  max-width: 42.5rem;
  font-size: 1rem;
  line-height: 1.7;
  margin: 0 auto;
  letter-spacing: 0.0075rem;

  @media (min-width: ${breakpoints.md}) {
    font-size: 1.125rem;

    p {
      margin-bottom: ${rhythm(1)};
    }
  }

  a:not([class]) {
    color: ${colors.accent.purple};
    text-decoration: none;
    border-bottom: 1px solid rgba(${hexToRgb(colors.accent.purple)}, 0.4);
    transition: all ${transition.speed.fast} ${transition.curve.scaleUp};

    &:hover {
      border-bottom-color: rgba(${hexToRgb(colors.accent.purple)}, 1);
    }
  }

  ${'' /* > p {
    &:first-child {
      font-size: 1.125rem;
      color: ${colors.text.darkGray};

      @media (min-width: ${breakpoints.md}) {
        font-size: 1.5rem;
      }
    }
  } */}

  .anchor {
    float: left;
    padding-right: 4px;
    margin-left: -20px;
  }

  blockquote {
    margin-left: 0;
    border-left: 3px solid rgba(${hexToRgb(colors.background.dark)}, 0.2);
    padding-left: ${rhythm(1)};
  }

  h1,
  h2,
  h3,
  h4,
  h5 {
    .anchor svg {
      visibility: hidden;
    }

    &:hover .anchor svg,
    .anchor:focus svg {
      visibility: visible;
    }
  }

  > p > .gatsby-resp-image-wrapper {
    margin-left: -8vw !important;
    margin-right: -8vw !important;

    @media (min-width: ${breakpoints.md}) {
      margin: ${rhythm(1)} -${rhythm(3)} !important;
      border-radius: 28px;
      overflow: hidden;
    }

    @media (min-width: ${breakpoints.lg}) {
      margin: ${rhythm(3 / 2)} -${rhythm(3)} !important;
    }
  }

  .gatsby-resp-image-image {
    height: auto;
    width: 100%;
    margin: 0;
    vertical-align: middle;
    position: absolute;
    top: 0;
    left: 0;
    color: transparent;
  }
`

const headerContent = css`
  grid-area: content;
`

const header = css`
  display: grid;
  padding: ${rhythm(1)} 0;
  margin: 0 auto ${rhythm(1)};
  align-items: center;
  border-bottom: 2px solid ${colors.background.lightGray};
  grid-template-columns: 100%;
  column-gap: ${rhythm(2)};
  grid-template-areas:
    'subheader'
    'content'
    'cover'
    'author';

  @media (min-width: ${breakpoints.md}) {
    padding: ${rhythm(2)} 0;
    margin: 0 auto ${rhythm(2)};
    grid-template-columns: auto 40%;
    align-items: flex-start;
    grid-template-areas:
      'subheader subheader'
      'content cover'
      'author author';
  }

  @media (min-width: ${breakpoints.lg}) {
    grid-template-columns: auto 445px;
    column-gap: 10vw;
    align-items: center;
  }

  @media (min-width: ${breakpoints.xxl}) {
    column-gap: ${rhythm(6)};
  }
`

const headerHasNoCover = css`
  grid-template-areas:
    'subheader'
    'content'
    'author';

  .content {
    padding: ${rhythm(1)} 0 0;
    margin: 0 auto;
  }

  @media (min-width: ${breakpoints.md}) {
    grid-template-areas:
      'subheader subheader'
      'content content'
      'author author';

    .content {
      text-align: center;
      padding: ${rhythm(1)} ${rhythm(6)} ${rhythm(3)};
    }
  }

  .author {
    justify-content: center;
  }
`

const cover = css`
  width: 100%;
  border-radius: 8px;
  grid-area: cover;
  position: relative;
  z-index: 1;

  &::before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    background: ${colors.background.shadow};
    right: -${rhythm(1)};
    bottom: -${rhythm(1)};
    z-index: 0;
    border-radius: 8px;
  }

  .gatsby-image-wrapper {
    border-radius: 8px;
    overflow: hidden;
  }
`

const subheader = css`
  display: flex;
  justify-content: space-between;
  width: 100%;
  grid-area: subheader;
  margin-bottom: ${rhythm(1 / 2)};

  a {
    color: ${colors.accent.purple};
    text-decoration: none;
  }

  @media (min-width: ${breakpoints.md}) {
    margin-bottom: ${rhythm(2)};
  }
`

const postAuthor = css`
  margin-top: auto;
  grid-area: author;
  display: flex;
  align-items: center;
  margin-top: ${rhythm(2)};

  p {
    margin: 0;
  }
`

const postFooter = css`
  border-bottom: 2px solid ${colors.background.lightGray};
`

const postAuthorAvatar = css`
  display: block;
  margin-right: ${rhythm(1)};
`

const SinglePost = React.forwardRef(
  ({ post, author, pathname, locationState }, ref) => {
    let hasImage = Boolean(post.frontmatter.image)
    let headerClasses = cx({
      [header]: true,
      [headerHasNoCover]: !hasImage
    })

    return (
      <article className={container} ref={ref}>
        <header className={headerClasses}>
          <div className={subheader}>
            <Link to={`/blog/`}>Back to blog</Link>
            <p
              className={css`
                ${{
                  color: colors.text.lightGray
                }};
              `}
            >
              {post.timeToRead} min to read
            </p>
          </div>
          <div className={`${headerContent} content`}>
            <HeroHeading>{post.frontmatter.title}</HeroHeading>
            <Lead
              className={css`
                ${{
                  marginTop: '0 !important',
                  [`@media (max-width: ${breakpoints.md})`]: {
                    fontSize: '1rem'
                  }
                }};
              `}
            >
              {post.frontmatter.excerpt}
            </Lead>
          </div>
          <div className={`${postAuthor} author`}>
            {delve(author, 'photo.childImageSharp.fixed') && (
              <Img
                fixed={author.photo.childImageSharp.fixed}
                alt={author.title}
                className={postAuthorAvatar}
                style={{
                  width: '56px',
                  height: '56px'
                }}
              />
            )}
            <p>
              By <strong>{author && author.title}</strong>
              {hasImage ? <br /> : ', '}
              {post.frontmatter.date}
            </p>
          </div>
          {delve(post, 'frontmatter.image.childImageSharp.fluid') && (
            <div className={cover}>
              <Img
                fluid={post.frontmatter.image.childImageSharp.fluid}
                alt=""
              />
            </div>
          )}
        </header>
        {post.htmlAst && (
          <div className={postContent}>{renderAst(post.htmlAst)}</div>
        )}
        <footer className={postFooter}>
          <MetaBox title="Share">
            <SocialSharers pathname={pathname} title={post.frontmatter.title} />
          </MetaBox>
          {author && <Author {...author} />}
        </footer>
      </article>
    )
  }
)

export default SinglePost
