import React from 'react'
import { Meta } from 'react-head'

export function printFAQ(faq) {
  let faqList = faq.map(
    item => `
    {
      "@type": "Question",
      "name": "${item.question}",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "${item.answer}"
      }
    }
  `
  )

  return faqList.join(',')
}

const JsonLDFaq = ({ faq }) => (
  <Meta
    tag="script"
    type="application/ld+json"
    dangerouslySetInnerHTML={{
      __html: `
      {
        "@context": "https://schema.org",
        "@type": "FAQPage",
        "mainEntity": [${printFAQ(faq)}]
      }
      `
    }}
  />
)

const JsonLD = ({ data }) => {
  if (!data) {
    return null
  }

  return <>{data[0] && data[0].faq && <JsonLDFaq faq={data[0].faq} />}</>
}

export default JsonLD
