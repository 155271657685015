import React, { useState, useEffect } from 'react'

import { css } from 'linaria'
import cx from '../../utils/cx'
import useWindowScroll from '../../utils/hooks/useWindowScroll'
import { colors, transition } from '../../utils/theme'
import breakpoints from '../../utils/tokens/breakpoints'
import SocialSharers from './SocialSharers'

const hiddenBar = css`
  display: none;

  @media (min-width: ${breakpoints.lg}) {
    width: 100%;
    position: absolute;
    text-align: center;
    height: 100%;
    top: 0;
    opacity: 0;
    visibility: hidden;
    z-index: 2;
    display: block;

    .title {
      margin: 0;
      grid-column-start: 2;
      color: ${colors.text.lightGray};
    }

    .title,
    .socials {
      opacity: 0;
      transition: all ${transition.speed.xl} ${transition.curve.ease};
      transform: translateY(5px);
      will-change: transform, opacity;
    }

    &.visible {
      visibility: visible;
      opacity: 1;

      .title,
      .socials {
        opacity: 1;
        transform: translateY(0);
      }

      + div .cta,
      + div nav {
        opacity: 0;
        visibility: hidden;
        transform: translateY(3px);
        transition: none;
      }
    }
  }
`

const container = css`
  display: grid;
  align-items: center;
  height: 100%;

  @media (min-width: ${breakpoints.md}) {
    grid-template-columns: 1fr auto 1fr;
    padding: 0 4vw;
  }

  @media (min-width: ${breakpoints.xxl}) {
    max-width: 87.5rem;
    margin: 0 auto;
    grid-template-columns: 1fr auto 1fr;
  }
`

const progressBarPercentage = css`
  height: 2px;
  width: 100%;
  background: ${colors.accent.purple};
  box-shadow: 0 -2px 8px 0 rgba(249, 64, 98, 0.35);
  position: absolute;
  z-index: 9;
  bottom: 0;
  will-change: transform;
`

const Progress = ({ postTitle, pathname, postRef }) => {
  const [barVisible, setBarVisibility] = useState(false)
  const [progress, setProgress] = useState(0)

  const resetProgress = () =>
    setTimeout(() => {
      setProgress(0)
      setBarVisibility(false)
    }, 0)

  useEffect(() => {
    resetProgress()
  }, [postTitle])

  useWindowScroll(
    ({ y }) => {
      let windowHeight = Math.max(
        document.documentElement.clientHeight,
        window.innerHeight || 0
      )

      if (y > windowHeight / 2) {
        setBarVisibility(true)
      } else {
        setBarVisibility(false)
      }

      let documentHeight = postRef.current.clientHeight - windowHeight + 100
      let percent = Number((y / documentHeight) * 100).toFixed(2)

      if (percent <= 100) {
        setProgress(percent)
      } else {
        setProgress(100)
        setBarVisibility(false)
      }
    },
    [barVisible, progress]
  )

  let hiddenBarClasses = cx({
    [hiddenBar]: true,
    visible: barVisible
  })

  return (
    <>
      <div
        className={progressBarPercentage}
        style={{ transform: `translateX(-${100 - progress}%)` }}
      />
      <div className={hiddenBarClasses}>
        <div className={container}>
          <p className="title">{postTitle}</p>
          <SocialSharers pathname={pathname} title={postTitle} />
        </div>
      </div>
    </>
  )
}

export default Progress
